<template>
  <div>
    <div class="tw-w-full tw-mb-5">Settings</div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>