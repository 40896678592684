<template>
  <div>
    <h2 class="tw-mb-3 tw-text-lg">Not Found</h2>
    <div class="tw-w-full tw-h-[500px] dark:tw-bg-neutral-800 tw-rounded-lg tw-bg-white">
      <div class="tw-flex tw-p-5 tw-items-center tw-justify-center tw-w-full tw-h-full">
        <img :src="$frontend('assets/images/illustrations/404.svg')" class="tw-w-full" alt="">
      </div>
    </div>
      <div class="tw-h-[1000px]"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>