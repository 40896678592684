<template>
  <div>
    <button type="button" class="tw-w-[32px] tw-h-[32px] tw-rounded-full hover:tw-bg-neutral-100 dark:hover:tw-bg-neutral-800 tw-flex tw-items-center tw-justify-center">
        <icon class="tw-text-2xl tw-text-neutral-700 dark:tw-text-neutral-100" icon="ph:magnifying-glass" />
    </button>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>