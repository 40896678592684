<template>
  <div class="tw-h-screen -tw-mt-16 tw-flex tw-items-center tw-justify-center">
    <loader class="tw-scale-115" />
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>